@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .grid-col-xxl {
    @apply mx-[60px] grid grid-cols-12 gap-[24px];
  }

  .grid-col-xl {
    @apply mx-[70px] grid grid-cols-12 gap-[24px];
  }

  .grid-col-lg {
    @apply mx-[32px] grid grid-cols-12 gap-[24px];
  }

  .grid-col-md {
    @apply mx-[102px] grid grid-cols-12 gap-[24px];
  }

  .grid-col-sm {
    @apply mx-[12px] grid grid-cols-4 gap-[24px];
  }

  .sn-grid {
    @apply grid-col-sm md:grid-col-md lg:grid-col-lg xl:grid-col-xxl xxl:grid-col-xxl;
  }

  @layer base {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

html,
body,
#__next,
#__font-container {
  @apply h-full;
}

.carousel-pagination-horizontal {
  @apply !bottom-0 flex items-center justify-center gap-x-[8px];
}

.carousel-pagination-bullet {
  @apply h-[8px] w-[8px] rounded-[3px] bg-dawn-blue-400 opacity-100;
}

.carousel-pagination-bullet-active {
  @apply h-[10px] w-[10px] rounded-[4px] bg-salmon opacity-100;
}

/* Required so that React Modal animates in and out */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
